<template>
  <div class="carfax__wrapper" >
    <div class="carfax__header">
      Щоб отримати Car Fax вставте VIN код автомобіля
    </div>

    <div class="carfax__action">
      <div>
        <input class="input" type="text" v-model="vin" placeholder="Він код">
      </div>
      <div v-if="!createLoader">
        <custom-btn :title="'Сканувати'" :placeholder="'1GYS4HKJ3GR176843'" @click="vinCodeCheck"></custom-btn>
      </div>
      <div class="center" v-else>
        <loaderSmall/>
      </div>
    </div>

    <div class="carfax__histories" v-if="!loader" >
      <carfax-doc v-for="doc in documents" :doc="doc" :key="doc.id"></carfax-doc>
    </div>
    <div v-else class="carfax__histories">
        <Skeleton height="89px" class="doc-skeleton"></Skeleton>
        <Skeleton height="89px" class="doc-skeleton"></Skeleton>
        <Skeleton height="89px" class="doc-skeleton"></Skeleton>
    </div>
  </div>
</template>

<script>
import customBtn from "@/components/custom-btn";
import axiosClient from "@/axios/axios";
import {Skeleton} from "vue-loading-skeleton";
import carfaxDoc from "@/components/carfax-doc";
import loaderSmall from "@/components/loader-small";

export default {
  name: "car-fax-index",
  components: {customBtn,Skeleton,carfaxDoc,loaderSmall},
  data(){
    return {
      vin: "",
      loader : true,
      createLoader: false,
      documents: []
    }
  },
  mounted() {
    this.getCarfaxWithUser()
  },
  methods:{
    async vinCodeCheck(){
      if(this.vin.length === 17 ) {
        this.createLoader = !this.createLoader;
        const {status} = await axiosClient.post("/analyse-carfax?vin=" + this.vin)
        if (status && status === 200) {
          await this.getCarfaxWithUser();
          this.createLoader = !this.createLoader;
        }
      }else{
        this.$notify({
          title: "Системне повідомлення!",
          type: 'error',
          text: "Він код має мати 17 символів, будь ласка перевірте!",
        })
      }
    },
    async getCarfaxWithUser()
    {
      const {data,status} = await axiosClient.get('/get-carfax-by-user-auth');
      if(status && status === 200){
        this.documents = data.data;
        this.documents = this.documents.map((value)=>{
          return {
            ...value,
            docLoading : false
          }
        })
        this.loader = false;
      }
    }
  }
}
</script>

<style scoped>
.carfax__histories{
  max-width: 1420px;
  margin: 25px auto;
}
.carfax__histories > .doc-skeleton {
  margin-bottom: 25px;
  height: 89px;
  display: block;

}
.carfax__histories > .doc{
  margin-bottom: 25px;
  height: 69px;
  background: #FFFFFF;
  border-radius: 10px;

  padding: 20px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}
.carfax__header{
  max-width: 540px;
  margin: 0 auto 25px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;

  text-align: center;
  color: #000000;
}
.carfax__action{
  max-width: 550px;
  margin: 0 auto;

}


@media (min-width: 320px) and (max-width: 1279px)  {
  .carfax__header{
    font-size: 24px;
    line-height: 30px;
  }
  .carfax__action > div:first-child{
    width: 100%;
    display: flex;
    margin-right: 15px;
  }
  .carfax__histories > .doc{
    height: max-content;
    box-shadow: 0px 2px 30px rgba(18, 31, 39, 0.15);

  }
  .carfax__wrapper{
    background: white;
    margin: 10px;
    border-radius: 14px;
    padding: 15px;
  }
}
@media (min-width: 1240px)  {
  .carfax__histories > .doc{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .carfax__header{
    font-size: 34px;
    line-height: 44px;
  }
  .carfax__action{
    display: flex;
    justify-content: space-between;
  }
  .carfax__action > div:first-child{
    width: 78%;
    display: flex;
    margin-right: 15px;
  }
}
</style>
